import * as React from 'react'
import classNames from 'classnames'
import * as c from '../../../../classnames.scss'
import * as s from './updated-indication.scss'
import {UpdatedIndicationProps} from '.'

export const UpdatedIndication = ({formattedUpdatedDate, t, notFirstItem}: UpdatedIndicationProps) => (
  <div className={classNames(s.container, {[s.notFirstItem]: notFirstItem})}>
    <div className={s.pimple} />
    <div className={classNames(s.updatedIndicationText, c.scheduleDurationFont)}>
      {t('updated')} {formattedUpdatedDate}
    </div>
  </div>
)
